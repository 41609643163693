import './App.css';
import React from "react";
import {Background} from "./Background";
import {BackgroundSmall} from "./BackgroundSmall";
import {Logo} from "./Logo";
import {CallToAction} from "./CallToAction";
import Sidebar from "react-sidebar";

function App() {
    const [open, setOpen] = React.useState(false);
    return (
    <>
      {/*<CallToAction />*/}
      <Sidebar
          sidebar={<><CallToAction /></>}
          open={open}
          onSetOpen={setOpen}
          styles={{ sidebar: { background: "white" } }}
          pullRight={true}
      >
        <button style={{
            // margin: "auto",
            border:"none",
            outline:"none",
            backgroundColor:"transparent"
            // width:"20%"
        }
                     } onClick={() => setOpen(true)}>
            <div className={"center_me"}>
                <Logo />
            </div>
        </button>
      </Sidebar>
    <Background />
    <BackgroundSmall/>
    </>
  );
}

export default App;
