import {Component} from "react";
import Sky from './skyupsidedown/Sky';
// import tough from "./images/tough-5ec-ag.png";
// function importAll(r) {
//     r.keys().map(r);
//     const obj = {};
//     var count = 0
//     for (const key of r.keys()) {
//         obj[count] = key.replace("./", "./images/");
//         count++
//     }
//     return obj
// }


export class Background extends Component {

    render() {
        // const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));
        // console.log(images);

        return (
            <div>

                <Sky
                    images={{
                        0: "https://electricbluefish.com/images/Bi-pack_sheet.12.jpg",
                        1: "https://electricbluefish.com/images/CompetitiveDieAttachReport_CDAR.8_Page_1.jpg",
                        2: "https://electricbluefish.com/images/CompetitiveDieAttachReport_CDAR.8_Page_2.jpg",
                        3: "https://electricbluefish.com/images/Datasheet.og198-55.9.jpg",
                        4: "https://electricbluefish.com/images/LD Module for Sid-01.jpg",
                        5: "https://electricbluefish.com/images/Screen Shot 2021-10-06 at 9.42.17 PM.png",
                        6: "https://electricbluefish.com/images/Screen Shot 2021-10-06 at 9.42.54 PM.png",
                        7: "https://electricbluefish.com/images/Screen Shot 2021-10-06 at 9.43.34 PM.png",
                        8: "https://electricbluefish.com/images/Screen Shot 2021-10-06 at 9.44.44 PM.png",
                        9: "https://electricbluefish.com/images/Screen Shot 2021-10-06 at 9.52.06 PM.png",
                        10: "https://electricbluefish.com/images/Screen Shot 2021-10-06 at 9.53.03 PM.png",
                        11: "https://electricbluefish.com/images/Screen Shot 2021-10-06 at 9.55.05 PM.png",
                        12: "https://electricbluefish.com/images/UV SYRINGE for Sid.2.jpg",
                        13: "https://electricbluefish.com/images/educational_logo_2.png",
                        14: "https://electricbluefish.com/images/epotech_MED_logo.jpg",
                        15: "https://electricbluefish.com/images/epotech_hyb_7.png",
                        16: "https://electricbluefish.com/images/globalspec_banner_2.jpg",
                        17: "https://electricbluefish.com/images/medical_brochure_78.lowres_Page_2.jpg",
                        18: "https://electricbluefish.com/images/medical_brochure_78.lowres_Page_3.jpg",
                        19: "https://electricbluefish.com/images/poster_ver9.jpg",
                        20: "https://electricbluefish.com/images/pullupbanner-34by81.3_flat.jpg",
                        21: "https://electricbluefish.com/images/spotlight_header.jpg",
                        22: "https://electricbluefish.com/images/testonly_donotuse.jpg",
                        23: "https://electricbluefish.com/images/tough-5ec-ag.png",
                    }}
                    how={10} /* Pass the number of images Sky will render chosing randomly */
                    time={50} /* time of animation */
                    size={'600px'} /* size of the rendered images */
                    background={'palettedvioletred'} /* color of background */
                    direction={"up"}
                />
            </div>
        );
    }
}