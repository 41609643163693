export function CallToAction(close) {
    return<div style={{
        padding:"15px"
    }}>

        <div align="center" style={{fontSize:"9pt", fontFamily:"Arial,Helvetica,sans-serif"}}>
            <h1>Website Under Construction</h1>
            <h4 >Contact Electric Bluefish Productions Inc.</h4>
                <p>
                    <span style={{color:"#660099",fontSize:"10pt"}}><a href={"tel:6104660888"}> p.610.466.0888 </a> &nbsp;</span></p><a href="mailto:info@electricbluefish.com"
            style={{color:"blue", textDecoration:"underline"}} title="Original URL: https://www.apple.com/ios/messages/. Click or tap if you trust this link." data-linkindex="7">email</a>:&nbsp;
            <font color="#00C7FD"><a href="mailto:info@electricbluefish.com" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" style={{color:"blue",fontSize:"9pt", textDecoration:"underline"}} data-linkindex="8"></a>
                <a href="mailto:info@electricbluefish.com" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" style={{color:"blue",fontSize:"9pt",textDecoration:"underline"}} data-linkindex="9">info@electricbluefish.com</a>
            </font>
        </div>
        {/*<button className={"close"} onClick={() => setOpen(false)}>Close</button>*/}
    </div>
    ;
}